import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Container } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { FcOk } from 'react-icons/fc'

import CampaignCard from '../components/CampaignCard'

import { axiosRequest } from '../utils'
import ThemeContext from '../providers/ThemeContext'
import Loading from '../components/Loading'


const SuccessPage = (props) => {
  const [ imageLoaded, setImageLoaded ] = useState(false)
  const [ dataLoaded, setDataLoaded ] = useState(false)
  const [ campaign, setCampaign ] = useState(null)
  const { setBackgroundColor, setButtonColor } = useContext(ThemeContext)

  useEffect(() => {
    const getData = () => axiosRequest({
      url: `/campaign/${props.match.params.id}`,
    }).then((res) => {
      // console.log(res)
      setCampaign(res.data)
      setBackgroundColor(res.data.backgroundColor)
      setButtonColor(res.data.buttonColor)
      setDataLoaded(true)
    })
    getData()
  },[props.match.params.id, setBackgroundColor, setButtonColor])

  if(!dataLoaded || !campaign) return <Loading />

  return (
    <>
      <Container>
        <Row className="pt-3">
          <Col>
            <CampaignCard data={campaign} dataLoaded={dataLoaded} imageLoaded={imageLoaded} setImageLoaded={setImageLoaded}>
              <div className="text-center" style={{ padding: '20px 0'}}>
                <p>{ imageLoaded && dataLoaded ? <FcOk size="60" /> : <Skeleton circle={true} height={60} width={60} />}</p>
                <p style={{ fontSize: '1.5rem'}}>{ imageLoaded && dataLoaded ? <strong>{campaign.language === 'EN' ? 'Registration successful' : 'สมัครรับคูปองสำเร็จ'}</strong> : <Skeleton  width={200} />}</p>
                <p>{ imageLoaded && dataLoaded ? <>{campaign.language === 'EN' ? 'We will send the coupons to you via SMS or Email that you have registered.' : 'ระบบจะส่งคูปองให้คุณผ่านทาง SMS หรือ Email ที่คุณได้ลงทะเบียนไว้'}</> : <Skeleton  width={350} />}</p>
              </div>
            </CampaignCard>
          </Col>
          </Row>
        </Container>
    </>
  )
}

export default SuccessPage