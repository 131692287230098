import React from 'react'
import { FcLeave } from 'react-icons/fc'
import styled from 'styled-components'

const ErrorComponent = ({ errorCode, errorText }) => {

  return (
    <Wrapper>
          <FcLeave size="64" />
          <br/><br/>
          <h2>{errorCode}</h2>
          <span>{errorText}</span>
    </Wrapper>
  )
}

export default ErrorComponent

const Wrapper = styled.div`
  position: absolute;
  top:50%;
  left: 50%;
  transform:translate(-50%, -50%);
  text-align: center;

  span{
    font-size: 1.2em;
  }
  
`