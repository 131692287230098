import React, { useState, useEffect,useContext } from 'react'
import { Row, Col, Container, CardText, Alert } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { parse as dateParse, compareAsc } from 'date-fns'
import parse from 'html-react-parser'
import styled from 'styled-components'
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent'
import ReactGA from 'react-ga4'
import config from '../analyticsGTM.json'
import CampaignCard from '../components/CampaignCard'
import CampaignDesc from '../components/CampaignDesc'
import RegisterForm from '../components/RegisterForm'
import ErrorComponent from '../components/ErrorComponent'
import ThemeContext from '../providers/ThemeContext'
import TagManager from 'react-gtm-module'
// import LangContext from '../providers/LangContext'
import Loading from '../components/Loading'

import { axiosRequest } from '../utils'

const CampaignPage = (props) => {
  const [ imageLoaded, setImageLoaded ] = useState(false)
  const [ dataLoaded, setDataLoaded ] = useState(false)
  const [ campaign, setCampaign ] = useState(null)
  const [ inTime, setInTime ] = useState('')
  const [ timeMsg, setTimeMsg ] = useState('')
  const [ errorCode, setErrorCode ] = useState('')
  const [ errorText, setErrorText ] = useState('')
  const { setBackgroundColor, setButtonColor } = useContext(ThemeContext)
  
  useEffect(() => {
    // console.log('APP_TITLE', process.env.APP_TITLE)
    const getData = () => axiosRequest({
      url: `/campaign/${props.match.params.id}`,
    }).then(async (res) => {
      // console.log(res)
      setBackgroundColor(res.data.backgroundColor)
      setButtonColor(res.data.buttonColor)
      const today = new Date()
      const start = dateParse(res.data.startDate, 'dd/MM/yyyy', new Date())
      const end = dateParse(res.data.endDate, 'dd/MM/yyyy', new Date())
      // console.log(compareAsc(today, start) , compareAsc(today, end))
      if(res.data.status === 'BEFORE') { //before time
        setInTime(-1)
        setTimeMsg('ยังไม่ถึงระยะเวลาของแคมเปญนี้<br />กรุณากลับมาใหม่อีกครั้งค่ะ')
      } else if (res.data.status === 'INTIME') { //in time
        setInTime(0)
        setTimeMsg('')
      } else if (res.data.status === 'AFTER') { //after time
        setInTime(1)
        setTimeMsg('สิ้นสุดระยะเวลาของแคมเปญนี้แล้ว<br />ขอขอบพระคุณทุกท่านที่ให้ความสนใจ')
      }
      setCampaign(res.data)
      setDataLoaded(true)
    }).catch((error) => {
      // const { response } = error
      // const { data } = response
      setErrorCode(error.response?.status)
      setErrorText( error.response?.data ? `${error.response?.data?.message}` : 'error')
      setDataLoaded(true)
    })
    getData() 

    let data = getCookieConsentValue('indexlivingmall')
    if (data === 'true') {

    } else {

    }
  },[props.match.params.id, setBackgroundColor, setButtonColor])

  // if(dataLoaded) return <Loading />
  if(dataLoaded && !campaign) return <ErrorComponent errorCode={errorCode} errorText={errorText} />

  if(!dataLoaded || !campaign) return <Loading />
  // console.log(start, end,compareAsc(start, end))
  
  return (
    <>
    {/* {campaign.backgroundColor} */}
      <Container style={{ paddingBottom: '50px', maxWidth: 800}}>
        <Row className="py-3">
          <Col>
            <CampaignCard data={campaign} dataLoaded={dataLoaded} imageLoaded={imageLoaded} setImageLoaded={setImageLoaded}>
              <CampaignDesc campaign={campaign} dataLoaded={dataLoaded} imageLoaded={imageLoaded} lang={campaign.language} />
              <LineDashed />
              
              {
                inTime === 0 &&
                <>
                  <CardText style={{ marginBottom: 0}}><small>{dataLoaded && imageLoaded ? <>{campaign.language === 'EN' ? 'Fill in the information to receive the discount coupon.' : 'กรอกข้อมูลเพื่อรับคูปองส่วนลด'}</> : <Skeleton width={200} /> }</small></CardText>
                  <RegisterForm loaded={imageLoaded && dataLoaded} campaignId={props.match.params.id} lang={campaign && campaign.language} />
                </>
              }
              { inTime !== 0 && timeMsg && <Alert color="warning" className="text-center mb-0">{parse(timeMsg)}</Alert> }
            </CampaignCard>
          </Col>
          </Row>
        </Container>
      {<CookieConsent
        location="bottom"
        buttonText="Accept"
        // declineButtonText="decline"
        // enableDeclineButton
        cookieName="indexlivingmall"
        cookieValue="true"
        style={{ background: "#2B373B", borderRadius: "4px" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "4px" }}
        declineButtonStyle={{ fontSize: "13px", borderRadius: "4px", background: '#ae1010' }}
        expires={1}
        flipButtons
        onDecline={() => {
          // let data = Cookies.get()
          // for (const [key, value] of Object.entries(data)) {
          //   Cookies.remove(key);
          // }
        }}
        onAccept={(accepted) => {
          // ReactGA.initialize(config.gtm)
          // config.event.forEach((data) => {
          //   ReactGA.event(data)
          // })
          TagManager.initialize(config.gtm)
          window.location.reload()
        }
        }
      >
        <span style={{ fontFamily: 'kanit', fontSize: '12pt' }}>เว็บไซต์ Index Living Mall ใช้คุกกี้เพื่อประสบการณ์การใช้งานที่ดี การคลิกปุ่มยอมรับหรือใช้งานเว็บไซต์ของเราถือเป็นการยอมรับ. </span>

        <span style={{ textDecorationLine: 'underline', fontSize: '12pt', fontWeight: 500, fontFamily: 'kanit' }}><a href="https://www.indexlivingmall.com/cookie-policy" target="_blank" rel="noreferrer" style={{ color: '#6caaff' }} >นโยบายการใช้งานคุกกี้</a></span>
      </CookieConsent>}
    </>
  )
}

export default CampaignPage

const LineDashed = styled.hr`
  border-top: 1px dashed #D9D9D9;
`
