import React, { createContext, useState } from 'react'
import styled from 'styled-components'

const ThemeContext  = createContext()

export const ThemeProvider = (props) => {
  const { children } = props
  const [ backgroundColor, setBackgroundColor ] = useState('#ffff')
  const [ buttonColor, setButtonColor ] = useState('#FEC32C')

  
  return (
    <ThemeContext.Provider
      value={{ 
        backgroundColor, setBackgroundColor,
        buttonColor, setButtonColor
       }}
    >
      <Wrapper backgroundColor={backgroundColor}>
        {children}
      </Wrapper>
    </ThemeContext.Provider>
  )
}

export default ThemeContext

const Wrapper = styled.div`
  .App {
    background: ${props => props.backgroundColor};
  }
`