import axios from 'axios'

export function axiosRequest({
  url,
  method = 'get',
  data,
}) {
  // const env = process.env.NODE_ENV || 'development'

  return axios(
    {
      url: `${process.env.REACT_APP_ENDPOINT}${url}`,
      method,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    },
)
}