import React, { useContext } from 'react'
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import styled from 'styled-components'
import { RiCouponLine } from 'react-icons/ri'
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io'

import CouponContext from '../providers/CouponProvider'

const CouponConfirmModal = ({ isShowing, toggle, lang }) => {
  const { usingCoupon, buttonLoad } = useContext(CouponContext)

  const confirm = async () => {
    toggle()
    await usingCoupon()

    //fix api if unsuccesss must not show coupon
    // fix button load to context
  }

  return (
    <Modal isOpen={isShowing} toggle={toggle} centered>
      {buttonLoad && 
      <Overlay>
        <Spinner style={{ position: 'absolute', width: 50, height: 50, top: 'calc(50% - 25px)', left: 'calc(50% - 25px)' }} />
      </Overlay>
      }
      
      <ModalBody>
        <div className="text-center" style={{ fontSize: '18px'}}>
        <br />
        <RiCouponLine size="44" />
        <br />
        แสดงคูปองกับพนักงานเพื่อใช้สิทธิ์<br/>
        คูปองใช้ได้ 1 ครั้งเท่านั้น
        <br /><br />
        Show this coupon at the cashier and can only be used once.
        {/* {lang === 'EN' ? 'Show this coupon at the cashier and can only be used once.' : 'แสดงคูปองนี้ที่แคชเชียร์ และสามารถใช้ได้ครั้งเดียวเท่านั้น'}
        <br />
        {lang === 'EN' ? '' : 'Would you like to use a coupon?'} */}
          </div>
      </ModalBody>
      <ModalFooterStyled>
        <ButtonStyled onClick={toggle}>
          <IoIosCloseCircle size="36" color="#dc3545" /> {lang === 'EN' ? 'Cancel' : 'ยกเลิก'}
        </ButtonStyled>
        <ButtonStyled onClick={confirm}>
        {lang === 'EN' ? 'OK' : 'ตกลง'} <IoIosCheckmarkCircle size="36" color="#28a745"  />
        </ButtonStyled>
      </ModalFooterStyled>
    </Modal>
  )
}

export default CouponConfirmModal

const ButtonStyled = styled.div`
padding: 15px;
cursor: pointer;
`

const ModalFooterStyled = styled(ModalFooter)`
  justify-content: space-between;
  padding: 0;
  margin: 0;
`

const Overlay = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.7);
  z-index: 1051;
  cursor: default;
  border-radius: 0.25rem;

`