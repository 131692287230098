import React from 'react'
import ReactDOM from 'react-dom'
// import ReactGA from 'react-ga4'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.css'

// ReactGA.initialize('GTM-WBKWJD9');

ReactDOM.render(
    <App />,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
