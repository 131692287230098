import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Button, Card, Spinner } from 'reactstrap'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import parse from 'html-react-parser'
import { parse as dateParse, format, isAfter, endOfDay } from 'date-fns'
import { FaSearch } from 'react-icons/fa'

import CampaignCard from '../components/CampaignCard'
import TermModal from '../components/TermModal'
import CouponConfirmModal from '../components/CouponConfirmModal'
import BarcodeEl from '../components/BarcodeEl'
import ErrorComponent from '../components/ErrorComponent'
import CouponContext, { CouponProvider } from '../providers/CouponProvider'

import useModal from '../hooks/useModal'

import { axiosRequest } from '../utils'
import UsedImg from '../assets/images/coupon_used.png'
import ExpiredImg from '../assets/images/coupon_expired.png'
import CampaignDesc from '../components/CampaignDesc'
import ThemeContext from '../providers/ThemeContext'
import Loading from '../components/Loading'
// import LangContext from '../providers/LangContext'


const monthNamesThai = ["ม.ค.","ก.พ.","มี.ค.","เม.ย.","พ.ค.","มิ.ย.",
"ก.ค.","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."]

const CouponsPage = (props) => {
  const [ imageLoaded, setImageLoaded ] = useState(false)
  const [ dataLoaded, setDataLoaded ] = useState(false)
  const [ data, setData ] = useState(null)
  const [ termText, setTermText ] = useState('')
  const [ errorCode, setErrorCode ] = useState('')
  const [ errorText, setErrorText ] = useState('')
  // const [ selected , setSelected ] = useState(null)
  const useTermModal = useModal()
  const useCouponConfirmModal = useModal()
  const { coupons, setCoupons, selected, setSelected, setUsed, buttonLoad, setLang } = useContext(CouponContext)
  const { setBackgroundColor, buttonColor, setButtonColor } = useContext(ThemeContext)
  // console.log(props)

  useEffect(() => {
    const getData = () => axiosRequest({
      url: `/coupon/link/${props.match.params.code}`,
    }).then((res) => {
      // console.log(res)
      setData(res.data)
      setCoupons(res.data.coupons)
      setLang(res.data.language)
      setBackgroundColor(res.data.backgroundColor)
      setButtonColor(res.data.buttonColor)
      setDataLoaded(true)
    }).catch((error) => {
      const { response = '' } = error
      const { data = '' } = response
      setErrorCode(response.status)
      setErrorText( data ? `${data.message}` : 'error')
      setDataLoaded(true)
    })
    getData()
  }, [props.match.params.code, setCoupons, setBackgroundColor, setButtonColor, setLang])

  const viewTerm = (data) => {
    setTermText(data)
    useTermModal.toggle()
  }

  const usingCoupon =(couponId, couponCode) => {
    setSelected(couponId)
    setUsed({
      link: props.match.params.code,
      code: couponCode,
     })
    useCouponConfirmModal.toggle()
  }

  const onLoad = () => {
    setImageLoaded(true)
  }
 
  if(dataLoaded && !data) return <ErrorComponent errorCode={errorCode} errorText={errorText} />

  if(!dataLoaded || !data) return <Loading />

  return (
    <>
    <TermModal data={termText} isShowing={useTermModal.isShowing} toggle={useTermModal.toggle} lang={data && data.language} />
    <CouponConfirmModal
    couponId={selected}
    isShowing={useCouponConfirmModal.isShowing}
    toggle={useCouponConfirmModal.toggle}
    lang={data && data.language}
    />
    <Container style={{ paddingBottom: '50px'}}>
    <Row className="pt-3">
      <Col>
        <CampaignCard data={data} dataLoaded={dataLoaded} imageLoaded={imageLoaded} setImageLoaded={setImageLoaded}>
            <CampaignDesc campaign={data} dataLoaded={dataLoaded} imageLoaded={imageLoaded} lang={data && data.language} />
        </CampaignCard>
      </Col>
    </Row>
    <Row className="pt-3 pb-3">
        {
          coupons && coupons.length > 0 ? coupons.map((item, i) => 
            {
              const dStart = dateParse(item.startDate, 'dd/MM/yyyy', new Date())
              const dEnd = dateParse(item.endDate, 'dd/MM/yyyy', new Date())
              const dExpiry = endOfDay(new Date(item.expiryDate))
              const couponExpired = item.expiryDate ? isAfter(new Date(), dExpiry) : null // if expiry_date is today, today can use.
              return (
                <Col key={`coupon-${item.id}`} xs={12} className="mb-3">
                  <CardStyled>
                    { item.isUsed === true && dataLoaded && imageLoaded &&
                      <Overlay display={'true'}>
                        <img alt="" src={UsedImg} />
                      </Overlay>
                    }
                    {/* { item.isUsed === false && item.status === 'AFTER' && dataLoaded && imageLoaded &&
                      <Overlay display={'true'}>
                        <img alt="" src={ExpiredImg} />
                      </Overlay>
                    } */}
                    { item.isUsed === false && ( couponExpired === true || item.status === 'AFTER' ) && dataLoaded && imageLoaded &&
                      <Overlay display={'true'}>
                        <img alt="" src={ExpiredImg} />
                      </Overlay>
                    }
                    {/* <Overlay display={item.isUsed === false && item.status === 'AFTER'}>
                      <img alt="" src={ExpiredImg} />
                    </Overlay> */}
                    <Wrapper>
                      <img className="card-img-top" alt="" src={item.image} onLoad={onLoad} style={{ display: `${dataLoaded && imageLoaded ? 'block': 'none'}`}} />
                    </Wrapper>
                    <ContentWrapper>
                      <CouponDesc>{dataLoaded && imageLoaded ? <>{parse(item.description)}</> : <Skeleton width={120} />}</CouponDesc>
                      {
                        data.customer?.name &&
                        <CouponCustomer>
                          {dataLoaded && imageLoaded  ? 
                            <>
                            {
                              data.language === 'EN' ? 
                              'Customer: '
                              :
                              'ชื่อลูกค้า: '
                            }
                            {data.customer?.name}
                            </>
                            : <Skeleton  width={120} /> 
                          }
                        </CouponCustomer>
                      }
                      {
                        item.expiryDate === null
                        && (
                          <CouponDate>
                            {dataLoaded && imageLoaded ? 
                              <>
                              {
                                data.language === 'EN' ? 
                                <>
                                  Available from: {item.startDate ? format(dateParse(item.startDate, 'dd/MM/yyyy', new Date()), 'dd MMM yyyy') : '-'}
                                  {
                                    item.showEndDate && ` - ${format(dateParse(item.endDate, 'dd/MM/yyyy', new Date()), 'dd MMM yyyy')}`
                                  }
                                </>
                                :
                                <>ใช้ได้ตั้งแต่ {`${dStart.getDate()} ${monthNamesThai[dStart.getMonth()]} ${(Number(dStart.getFullYear())+543).toString().substring(2,4)}`} - {`${dEnd.getDate()} ${monthNamesThai[dEnd.getMonth()]} ${(Number(dEnd.getFullYear())+543).toString().substring(2,4)}`}</>
                              }
                              
                              </>
                              : <Skeleton  width={120} /> 
                            }
                            </CouponDate>
                        )
                      }
                      {
                        item.expiryDate !== null &&
                        <CouponDate>
                            {
                              dataLoaded && imageLoaded ?
                                <>
                                {
                                  data.language === 'EN' ? 
                                  <>
                                    {'Expiry date: '}
                                    {item.expiryDate ? format(new Date(item.expiryDate), 'dd MMM yyyy') : '-'}
                                  </>
                                  :
                                  <>วันหมดอายุ: {`${dExpiry.getDate()} ${monthNamesThai[dExpiry.getMonth()]} ${(Number(dExpiry.getFullYear())+543).toString().substring(2,4)}`}</>
                                }
                                </>
                              : <Skeleton  width={120} />
                            }
                          
                        </CouponDate>
                      }
                      {dataLoaded && imageLoaded ? <>{ !item.isUsed && <PillButtonSm outline color="secondary" onClick={() => viewTerm(item.term)}><FaSearch /> {data.language === 'EN' ? 'Conditions' : 'ดูเงื่อนไข' }</PillButtonSm>}</> : <Skeleton width={80} /> }
                    </ContentWrapper>
                    {
                      item.isUsed || couponExpired === true || item.status === 'BEFORE' || item.status === 'AFTER' ?
                      <> </>
                      :
                      <>
                      
                      <LineDashed />
                      <ContentWrapper>
                        {
                          item.showBarcode === true && !buttonLoad ?
                          <>
                            <BarcodeEl code={item.couponCode} dataLoaded={dataLoaded} imageLoaded={imageLoaded} />
                            <div style={{ fontSize: '12px',marginTop: 10, textAlign: 'center' }}>{data.language === 'EN' ? '*Please show the barcode to the staff for the privilege*' : '*กรุณาแสดงบาร์โค้ดกับเจ้าหน้าที่เพื่อใช้สิทธิ์*'}</div>
                          </> :
                          <>
                          {dataLoaded && imageLoaded ?
                            <PillButton
                            // color="warning"
                            buttoncolor={buttonColor}
                            block
                            onClick={() => usingCoupon(item.id, item.couponCode)}
                            disabled={buttonLoad}
                            >
                            {
                              buttonLoad ?
                              <div style={{ position: 'relative', top: '-4px' }}>
                                <Spinner size="sm" color="light" />
                              </div>
                              : 
                              <>
                              Use Coupon (for staff use)
                              {/* {data.language === 'EN' ? 'Use Coupon (for staff use)' : 'กรุณาให้พนักงานกดรับสิทธิ์'} */}
                              </>
                              }
                          </PillButton> : <Skeleton height={44} />
                          }
                            <div style={{ paddingTop: 10}}>กรุณาให้พนักงานกดรับสิทธิ์</div>
                            
                          </>
                        }
                      </ContentWrapper>
                      </>
                    }
                   
                  </CardStyled>
                </Col>
              )
          }) : 
          <>
            <Col xs={12} className="mb-3">

            </Col>
          </>
        }
      </Row>
    </Container>
    </>
  )
}

export default props => <CouponProvider><CouponsPage {...props} /></CouponProvider>

const CardStyled = styled(Card)`
-webkit-box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.25);
box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.25);
`

const Overlay = styled.div`
  position: absolute;
  display: ${props => props.display === 'true' ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.7);
  z-index: 2;
  cursor: default;
  border-radius: 0.25rem;
  
  img {
    width: 50%;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    position: absolute;
  }
`

const CouponDesc = styled.div`
font-size: 16px;
color: #000000;
text-align: center;
margin-bottom: 20px;

p {
  margin-bottom: 0.5rem;
}

h1 {
font-size: 1.5rem;
}
h2 {
font-size: 1.25rem;
}
  h3 {
font-size: 1rem;
}
p { 
  font-size: 14px;
}
`

const ContentWrapper = styled.div`
flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem !important;
    text-align: center;
`

const CouponCustomer = styled.div`
font-size: 13px;
text-align: center;
margin-bottom: 20px;
`

const CouponDate = styled.div`
font-size: 13px;
text-align: center;
margin-bottom: 20px;
`

const PillButton = styled(Button)`
  padding: 0.25rem 1.25rem;
  height: 44px;
  font-size: 18px;

  color: #000;
  border-radius: 50px;
  background: ${props => props.buttoncolor};
  border: none;
  border-color: transparent;

   &:hover:not(:disabled) {
    color: #000;
    background: ${props => props.buttoncolor};
    border-color: transparent;
    filter: brightness(85%);
  }

  &:disabled {
    color: #212529;
    background: #f8f9fa;
    border-color: #f8f9fa;
  }
`

const PillButtonSm = styled(Button)`
  border-radius: 50px;
  padding: 0.25rem 1.5rem;
  font-size: x-small;
`

const LineDashed = styled.hr`
  border-top: 1px dashed #D9D9D9;
  margin: 0;
`

const Wrapper = styled.div`
  background-color: #cccccc;
  position: relative;
  padding-bottom: 56.25%;

  .card-img-top {
    position: absolute;
    height: 100%;
  }
`