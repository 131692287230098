import React from 'react'
import styled from 'styled-components'

const Loading = () => {
  return (
    
    <Wrapper>
    <LoadingStyled>
        <h1 className={`${process.env.REACT_APP_TITLE === 'BoConcept' ? 'boconcept' : 'ilm'}`} data-text={process.env.REACT_APP_TITLE}>{process.env.REACT_APP_TITLE}</h1>
        {/* <p data-text="Customized furniture 4.0">Customized furniture 4.0</p> */}
      
    </LoadingStyled>
    </Wrapper>
  )
}

export default Loading

const LoadingStyled = styled.div`
  font-weight: 800;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

`
const Wrapper = styled.div`
  height: 100%;
  
  h1 {
    margin: 0 auto;
    position: relative;
    color: #AAAAAA;
    
    
    white-space: nowrap;
  }

  h1.boconcept {
    font-size: 44px;
  }

  h1.ilm {
    font-size: 30px;
    text-transform: uppercase;
  }


  h1:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    
    white-space: nowrap;
    animation: loading 1.5s infinite;

  }

  
  h1.boconcept:before {
    max-width: 14rem;
    background: #ffb347; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #000, #000); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #000, #000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h1.ilm:before {
    max-width: 21rem;
    background: #ffb347; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ffcc33, #ffb347); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ffcc33, #ffb347); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @keyframes loading {
    0% {
      max-width: 0;
    }
  }

  p {
    text-align: center;
    color: var(--dark);
  }
`
