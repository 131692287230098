import React, { createContext, useState } from 'react'

const LangContext  = createContext()

export const LangProvider = (props) => {
  const { children } = props
  const { lang, setLang } = useState('TH')

  
  return (
    <LangContext.Provider
      value={{ 
        lang, setLang
       }}
    >
      {children}
    </LangContext.Provider>
  )
}

export default LangContext