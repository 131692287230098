import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, FormGroup, Label, Input, Button, Col, CustomInput, Spinner } from 'reactstrap'
import styled from 'styled-components'
import { useForm, ErrorMessage } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import useModal from '../hooks/useModal'
import ConsentModal from './ConsentModal'

import { axiosRequest } from '../utils'
import ThemeContext from '../providers/ThemeContext'

toast.configure()

const RegisterForm = (props) => {
  const { loaded, campaignId, lang } = props
  const [consent, setConsent] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const { buttonColor } = useContext(ThemeContext)
  const useConsentModal = useModal()
  const { register, errors, handleSubmit, watch } = useForm()
  const watchName = watch('name')
  const watchPhoneNumber = watch('phoneNumber')

  let history = useHistory()

  const onSubmit = (data) => {
    setButtonLoading(true)
    // console.log('submit', data)
    axiosRequest({
      url: `/register/campaign/${campaignId}`,
      method: 'POST',
      data
      // data: {
      //   name: 'b',
      //   email: 'b',
      //   phoneNumber: '0000000005',
      // },
    }).then((res) => {
      // console.log(res)
      if(res.status === 200) history.push(`/campaign/${campaignId}/success`)
      setButtonLoading(false)
    })
    .catch((error) => {
      const { response: { data: { message = '' } } } = error
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        progress: undefined,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        })
        setButtonLoading(false)
    })
  }

  const consentCheck = (value) => {
    setConsent(value)
    if(value === true) {
      useConsentModal.toggle()
    }
  }

  return (
    <>
      <ConsentModal isShowing={useConsentModal.isShowing} toggle={useConsentModal.toggle} lang={lang} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          {
            loaded ? 
            <>
              <Label for="name">{lang === 'EN' ? 'Full name' : 'ชื่อ - นามสกุล'} <span className="text-danger">*</span></Label>
              <Input 
                id="name" 
                name="name" 
                innerRef={register(
                  { 
                    required: 'กรุณากรอก ชื่อ-สกุล' },
                )}
              />
              <ErrorMessage errors={errors} name="name">
                {
                  ({ message }) => <ErrorText>{message}</ErrorText>
                }
              </ErrorMessage>
            </> : 
            <>
              <Skeleton width={100} />
              <Skeleton height={38} />
            </>
          }
        </FormGroup>
        <FormGroup>
          {
            loaded ? 
            <>
              <Label for="email">{lang === 'EN' ? 'Email' : 'อีเมล'}</Label>
              <Input 
                id="email" 
                name="email" 
                // type="email" 
                innerRef={register({ 
                    pattern: /\S+@\S+\.\S+/,
                    message: 'รูปแบบอีเมลไม่ถูกต้อง'
                  })
                }
              />
              <ErrorMessage errors={errors} name="email">
                {
                  ({ message }) => <ErrorText>{message}</ErrorText>
                }
              </ErrorMessage>
            </> :
            <>
              <Skeleton width={100} />
              <Skeleton height={38} />
            </> 
          }
        </FormGroup>
        <FormGroup>
          { loaded ? 
            <>
              <Label for="phoneNumber">{lang === 'EN' ? 'Phone number' : 'เบอร์โทรศัพท์'} <span className="text-danger">*</span></Label>
              <Input 
              id="phoneNumber" 
              name="phoneNumber" 
              type="number"
              maxLength={10}
              innerRef={register({
                required: 'กรุณากรอกเบอร์โทรศัพท์',
                minLength: {
                  value: 10,
                  message: 'กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลข 10 หลัก'
                },
                maxLength: {
                  value: 10,
                  message: 'กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลข 10 หลัก'
                }
              })}
              />
              <ErrorMessage errors={errors} name="phoneNumber">
                {
                  ({ message }) => <ErrorText>{message}</ErrorText>
                }
              </ErrorMessage>
            </> : 
            <>
              <Skeleton width={100} />
              <Skeleton height={38} />
            </>  
          }
        </FormGroup>
        <FormGroup check inline>
          {
            loaded ?
            <Label check>
              <CustomInputStyled type="checkbox" id="consent" name="consent" label={lang === 'EN' ? 'I accept the terms and conditions' : 'ข้าพเจ้ายอมรับเงื่อนไขและข้อตกลง'} value={consent} onChange={e => consentCheck(e.target.checked)}  />
            </Label> :
            <Skeleton width={250} />
          }
        </FormGroup>
        <FormGroup row className="mt-3 mb-0">
          <Col>
          { 
            loaded ?
            <PillButton
            type="submit"
            block 
            // color={!watchName || !watchPhoneNumber || !consent ? 'light' : 'warning'} 
            buttoncolor={buttonColor}
            disabled={!watchName || !watchPhoneNumber || !consent || buttonLoading}
            >
              {
              buttonLoading ?
              <div style={{ position: 'relative', top: '-4px' }}>
                <Spinner size="sm" color="light" />
              </div>
              : <>{lang === 'EN' ? 'Submit' : 'ส่งข้อมูล' }</>
              }
              
            </PillButton> :
            <Skeleton height={38} />
          }
           
          </Col>
        </FormGroup>
        
      </Form>
    </>
  )
}

export default RegisterForm

const PillButton = styled(Button)`
  color: #000;
  border-radius: 50px;
  background: ${props => props.buttoncolor};
  border-color: transparent;
  border: none;

   &:hover:not(:disabled) {
    color: #000;
    background: ${props => props.buttoncolor};
    border-color: transparent;
    filter: brightness(85%);
  }

  &:disabled {
    color: #212529;
    background: #f8f9fa;
    border-color: #f8f9fa;
  }

`

const ErrorText = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const CustomInputStyled = styled(CustomInput)`
  .custom-control-label { 
    font-size: 0.75em;
    line-height: 2em;
  }
`