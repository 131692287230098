import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import './App.css'

import CampaignPage from './pages/CampaignPage'
import CouponsPage from './pages/CouponsPage'
import SuccessPage from './pages/SuccessPage'
import { ThemeProvider } from './providers/ThemeContext'
import { LangProvider } from './providers/LangContext'

function App() {
  return (
    <div className="App">
      <Router>
          <Switch>
            <Route exact path="/campaign/:id" component={CampaignPage} />
            <Route exact path="/campaign/:id/success" component={SuccessPage} />
            <Route path="/coupons/:code" component={CouponsPage} />
          </Switch>
        </Router>
    </div>
  )
}

export default props => <ThemeProvider><LangProvider><App {...props} /></LangProvider></ThemeProvider>
