import React from 'react'
import { CardText } from 'reactstrap'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import parse from 'html-react-parser'
import { parse as dateParse, compareAsc, format } from 'date-fns'
import { AiTwotoneCalendar } from 'react-icons/ai'


const monthNamesThai = ["ม.ค.","ก.พ.","มี.ค.","เม.ย.","พ.ค.","มิ.ย.",
"ก.ค.","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."]

const CampaignDesc = props => {
  const { campaign, dataLoaded, imageLoaded, lang } = props

  const start = dateParse(campaign.startDate, 'dd/MM/yyyy', new Date())
  const end = dateParse(campaign.endDate, 'dd/MM/yyyy', new Date())
  
  return (
    <CardText>
      <CampaignTitle>{dataLoaded && imageLoaded ? `${campaign.name}` : <Skeleton width={200}/>}</CampaignTitle><br />
      <CampaignDescription>{dataLoaded && imageLoaded ? <>{campaign.description ? parse(campaign.description) : ''}</> : <Skeleton width={250}/>}</CampaignDescription>
      {/* hide campaign date */}
      {/* <br /> */}
      {/* {
          start && end &&
          <CampaignDate>
            {
            dataLoaded && imageLoaded ? 
            <>
            <span>
              <AiTwotoneCalendar />&nbsp;
              {
                lang === 'EN' ? `${format(dateParse(campaign.startDate, 'dd/MM/yyyy', new Date()), 'dd MMM yyyy')}` : <>{`${start.getDate()} ${monthNamesThai[start.getMonth()]} ${(Number(start.getFullYear())+543).toString().substring(2,4)}`}</>
              }
            </span>
            {
              campaign.showEndDate && compareAsc(start, end) !== 0 &&
                <span>
                  {
                    lang === 'EN' ? ` - ${format(dateParse(campaign.endDate, 'dd/MM/yyyy', new Date()), 'dd MMM yyyy')}` : ` - ${end.getDate()} ${monthNamesThai[end.getMonth()]} ${(Number(end.getFullYear())+543).toString().substring(2,4)}`
                  }
                  </span>
            }
              </> : <Skeleton width={250} />
              }
          </CampaignDate>
        } */}
      
    </CardText>
  )
}

export default CampaignDesc


const CampaignTitle = styled.span`
font-style: normal;
font-weight: bold;
font-size: 20px;
`

const CampaignDescription = styled.span`
white-space: pre-wrap;
font-style: normal;
font-weight: normal;
font-size: 14px;
`

const CampaignDate = styled.span`
font-style: normal;
font-weight: normal;
font-size: 14px;
`