import React, { createContext, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosRequest } from '../utils'

const CouponContext  = createContext()


export const CouponProvider = (props) => {
  const { children } = props
  const [ coupons, setCoupons ] = useState([])
  const [ selected, setSelected ] = useState(null)
  const [ used, setUsed ] = useState({})
  const [ buttonLoad, setButtonLoad ] = useState(false)
  const [ lang, setLang ] = useState('TH')

  const usingCoupon = async () => {
    setButtonLoad(true)
    if(selected) {
      const updatedCoupons = [...coupons]
      var index = updatedCoupons.findIndex(c => c.id === selected)
      const updatedObj = {...updatedCoupons[index], showBarcode: true }
      updatedCoupons[index] = updatedObj
      setCoupons(updatedCoupons)
      await axiosRequest({
        url: `/coupon/link/${used.link}/code/${used.code}`,
        method: 'PUT'
      }).then((res) => {
        if(res.status === 200) {
          toast.success(`${lang === 'EN' ? 'Successfully used the coupon.' : 'กดใช้คูปองสำเร็จ'}`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          hideProgressBar: true,
          progress: undefined,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setButtonLoad(false)
      } else {
        toast.error(`${lang === 'EN' ? 'Coupons cannot be used.' : 'ไม่สามารถใช้คูปองได้'}`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          hideProgressBar: true,
          progress: undefined,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          })
          setButtonLoad(false)
      }
      })
      .catch((error) => {
      const { response: { data: { message = '' } } } = error
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        progress: undefined,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        })
        setButtonLoad(false)
    })
      
    }
  }
  
  return (
    <CouponContext.Provider
      value={{ coupons, setCoupons, selected, setSelected, usingCoupon, used, setUsed, buttonLoad, setButtonLoad, lang, setLang }}
    >
      {children}
    </CouponContext.Provider>
  )
}

export default CouponContext