import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import parse from 'html-react-parser'
import styled from 'styled-components'

const htmlSnippet = `
<h4>รายละเอียดทั่วไป</h4>
<p>	ระเบียบปฏิบัติการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ จัดทำขึ้นเพื่อชี้แจงรายละเอียดและเป็นแนวทางในการเก็บรวบรวม จัดเก็บรักษา ใช้ รวมถึงการเปิดเผยข้อมูลส่วนบุคคล อย่างถูกต้อง เหมาะสม และปลอดภัย เพื่อปกป้องสิทธิต่าง ๆ ของเจ้าของข้อมูลส่วนบุคคล ได้แก่ ลูกค้า
    ผู้ใช้บริการ พนักงาน หรือผู้มีส่วนได้ส่วนเสียอื่นๆ ซึ่งมีการให้ข้อมูลส่วนบุคคลมายังบริษัทฯ โดยตรง หรือผ่านระบบข้อมูลสารสนเทศของบริษัทฯ และเพื่อเป็นการปฏิบัติตามข้อกำหนดใน พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562</p>
<h4>คำจำกัดความ</h4>
<p>กลุ่มบริษัท&nbsp;หมายถึง บริษัท อินเด็กซ์ ลิฟวิ่งมอลล์ จำกัด (มหาชน) และบริษัทย่อย</p>
<p>บริษัทฯ&nbsp;หมายถึง บริษัท อินเด็กซ์ ลิฟวิ่งมอลล์ จำกัด (มหาชน) โดยให้รวมถึงผู้ซึ่งได้รับมอบอำนาจให้กระทำการแทนบริษัทฯ หรือผู้ได้รับมอบหมายให้ทำงานในนามบริษัทฯ</p>
<p>บริษัทย่อย&nbsp;หมายถึง บริษัทที่บริษัทฯ ถือหุ้นเกินกว่าร้อยละ 50</p>
<p>ข้อมูลส่วนบุคคล&nbsp;หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม</p>
<p>ผู้ที่ได้รับมอบหมายอย่างเป็นทางการ&nbsp;หมายถึง ผู้ที่ผู้บริหารสูงสุดมอบหมายให้ดูแลรับผิดชอบและปฏิบัติงาน</p>
<p>คุกกี้ (Cookies)&nbsp;หมายถึง ชิ้นส่วนข้อมูลเล็ก ๆ ที่เก็บอยู่ในอุปกรณ์ของลูกค้าหรือผู้ใช้บริการหรือผู้มีส่วนได้เสีย ที่จะทำให้เว็บไซต์ สามารถจดจำข้อมูลการเข้าถึงเว็บไซต์หรือวิธีใช้งานเว็บไซต์ในแต่ละครั้ง</p>
<h4>การเคารพสิทธิในความเป็นส่วนตัวของเจ้าของข้อมูลส่วนบุคคล</h4>
<p>	บริษัทฯ เคารพและให้ความสำคัญถึงสิทธิข้อมูลส่วนบุคคลและการคุ้มครองข้อมูลส่วนบุคคลและตระหนักดีว่าเจ้าของข้อมูลส่วนบุคคลย่อมมีความประสงค์ที่จะได้รับความมั่นคงปลอดภัยในการใช้บริการ ข้อมูลส่วนบุคคลที่บริษัทฯ และ/หรือ กลุ่มบริษัทฯ ได้รับมา เช่น ชื่อ อายุ ที่อยู่
    หมายเลขโทรศัพท์ หมายเลขบัตรประชาชน ข้อมูลทางการเงิน เป็นต้น ซึ่งสามารถบ่งบอกตัวบุคคลของเจ้าของข้อมูลได้ และเป็นข้อมูลส่วนบุคคลที่มีความสมบูรณ์ ถูกต้อง เป็นปัจจุบัน และมีคุณภาพ จะถูกนำไปใช้ให้เป็นไปตามวัตถุประสงค์การดำเนินงานของบริษัทฯ เท่านั้น โดยจะดำเนินมาตรการที่เข้มงวดในการรักษาความมั่นคงปลอดภัย
    ตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิได้รับอนุญาตจากเจ้าของข้อมูลก่อน ทั้งนี้บริษัทฯ จะแจ้งเหตุการละเมิดข้อมูลส่วนบุคคลแก่เจ้าของข้อมูลทันทีที่เกิดเหตุละเมิด</p>
<h4>การจัดเก็บรวบรวมข้อมูลส่วนบุคคล</h4>
<p>	บริษัทฯ มีการเก็บรวบรวมข้อมูลส่วนบุคคลจากหลากหลายช่องทาง ซึ่งข้อมูลส่วนบุคคลที่เก็บอาจประกอบไปด้วยข้อมูลชนิดต่าง ๆ ดังนี้</p>
<p>ข้อมูลที่ลูกค้าหรือผู้ใช้บริการหรือผู้มีส่วนได้เสียให้ไว้โดยตรง&nbsp;ได้แก่</p>
<ol>
    <li>ข้อมูลติดต่อส่วนบุคคล เช่น ชื่อ นามสกุล เบอร์โทรศัพท์ ที่อยู่ อีเมล</li>
    <li>ข้อมูลในการยืนยันตัวตน เช่น ชื่อผู้ใช้งาน รหัสผ่าน หมายเลขบัตรประชาชนหรือหนังสือเดินทาง</li>
    <li>ข้อมูลส่วนบุคคลอื่น ได้แก่ วัน/เดือน/ปีเกิด เพศ อายุ เชื้อชาติ สัญชาติ ศาสนา รูปภาพ</li>
    <li>ข้อมูลอื่น ๆ ซึ่งปรากฎอยู่ในแบบฟอร์มในการกรอกข้อมูล รวมถึงเอกสารที่ใช้ประกอบแบบฟอร์ม</li>
</ol>
<p>ข้อมูลที่ลูกค้าหรือผู้ใช้บริการหรือผู้มีส่วนได้เสียใช้บริการ&nbsp;ได้แก่</p>
<ol>
    <li>ข้อมูลจราจรทางคอมพิวเตอร์ (Log) ได้แก่ หมายเลขไอพี วัน เวลาที่เข้าใช้งาน รหัสประจำตัวอุปกรณ์ ประเภทอุปกรณ์ ข้อมูลเครือข่ายมือถือ ข้อมูลการเชื่อมต่อ ข้อมูลตำแหน่งที่ตั้งทางภูมิศาสตร์ ประเภทของเบราว์เซอร์ (Browser) ข้อมูลบันทึกการเข้าออกเว็บไซต์ ข้อมูลเว็บไซต์ที่ผู้ใช้งานเข้าถึงก่อนและหลัง
        (Referring Website) ข้อมูลบันทึกประวัติการใช้เว็บไซต์ ข้อมูลบันทึกการเข้าสู่ระบบ (Login Log) ข้อมูลรายการทำธุรกรรม (Transaction Log)</li>
    <li>ข้อมูลการบันทึกเสียง เมื่อมีการติดต่อเข้ามายังบริษัทฯ หรือบันทึกรายละเอียดการติดต่อ</li>
    <li>ข้อมูลซึ่งเกิดจากการวิเคราะห์และรวบรวมสถิติในการใช้บริการหรือใช้งานระบบ เช่น พฤติกรรมการใช้งาน (Customer Behavior) สถิติการเข้าเว็บไซต์ เวลาที่เยี่ยมชมเว็บไซต์ (Access Time) ข้อมูลที่มีการค้นหา การใช้ฟังก์ชั่นต่าง ๆ ในเว็บไซต์ และข้อมูลที่บริษัทฯ
        ได้เก็บรวบรวมผ่านคุกกี้ (Cookies)</li>
</ol>
<p>	บริษัทฯ ทำการจัดเก็บข้อมูลส่วนบุคคลดังกล่าวเพื่อดำเนินการตามวัตถุประสงค์การดำเนินงานของบริษัท ฯ อันชอบด้วยกฎหมาย และเพื่อปรับปรุงการดำเนินงานของบริษัทฯ ให้มีประสิทธิภาพมากยิ่งขึ้น โดยบริษัทฯ จะจัดเก็บข้อมูลส่วนบุคคลเฉพาะข้อมูลที่จำเป็นต่อการดำเนินงานเท่านั้น
    อาทิ</p>
<ol>
    <li>ใช้สำหรับทำรายการ / กิจกรรมที่เกี่ยวข้องกับการดำเนินธุรกิจของบริษัทฯ</li>
    <li>ใช้เพื่อประโยชน์ในการศึกษา วิจัย จัดทำสถิติ</li>
    <li>ใช้เพื่อพัฒนาการให้บริการ จัดทำการตลาด การโฆษณาประชาสัมพันธ์ การส่งจดหมายข่าวผลิตภัณฑ์ โปรโมชั่นต่าง ๆ ที่เกี่ยวข้องกับสินค้าและบริการของบริษัทฯ</li>
    <li>ใช้ปรับปรุง พัฒนา เพิ่มประสิทธิภาพของการดำเนินงานของบริษัทฯ</li>
    <li>ใช้สำหรับรับเรื่องร้องเรียน แนะนำ ติชม หรือแสดงความคิดเห็น</li>
    <li>ใช้สำหรับติดต่อเจ้าของข้อมูลผ่านช่องทางใด ๆ เพื่อสอบถาม แจ้งให้ทราบ ตรวจสอบ ยืนยันข้อมูลเกี่ยวกับเจ้าของข้อมูล หรือสำรวจความคิดเห็นตามความจำเป็น</li>
    <li>ใช้สำหรับตรวจสอบข้อมูล เพื่อให้เกิดความเรียบร้อย สอดคล้องกับกฎหมาย หลักเกณฑ์ กฎเกณฑ์ที่เกี่ยวข้องที่มีการใช้บังคับในปัจจุบันและที่จะมีการแก้ไขหรือเพิ่มเติมในอนาคต</li>
</ol>
<p>	หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล บริษัทฯ จะแจ้งหรือประชาสัมพันธ์ให้เจ้าของข้อมูลรับทราบโดยไม่รีรอ ในการจัดเก็บข้อมูลส่วนบุคคลดังกล่าว บริษัทฯ กระทำได้โดยรับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลหรือผู้มีส่วนได้เสีย แล้วเท่านั้น
    เพื่อให้การดำเนินธุรกิจของบริษัทเป็นไปอย่างราบรื่น หรือเพื่อการปฏิบัติหน้าที่ที่บริษัทมีต่อลูกค้า บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลต่อผู้ให้บริการซึ่งเป็นบุคคลที่สาม ตัวแทน บริษัทย่อย หรือบริษัทที่เกี่ยวข้องที่ตั้งอยู่ภายในหรือภายนอกประเทศไทย เพื่อวัตถุประสงค์อย่างใดอย่างหนึ่งหรือหลายอย่างตามการวัตถุประสงค์ของการจัดเก็บ
    นำไปใช้ เปิดเผย และดำเนินการกับข้อมูลส่วนบุคคลที่ได้แจ้ง นอกจากนี้บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลต่อเจ้าหน้าที่หรือหน่วยงานของรัฐ เพื่อให้เป็นไปตามกฎหมาย กฎ แนวทาง ระเบียบ หรือระบบการจัดการที่บังคับใช้กับบริษัท โดยบริษัทฯ จะกำหนดให้หน่วยงานบุคคลที่สามดำเนินการเก็บรักษาความลับ
    ความปลอดภัยของข้อมูลส่วนบุคคล และกำหนดข้อห้ามมิให้มีการนำข้อมูลส่วนบุคคลดังกล่าวไปใช้ เพื่อวัตถุประสงค์อื่นนอกจากวัตถุประสงค์ตามที่บริษัทฯ กำหนด ซึ่งตัวอย่างบุคคลที่สามที่บริษัทจะเปิดเผยข้อมูลส่วนบุคคลให้ทราบ มีดังนี้</p>
<ol>
    <li>ผู้ให้บริการนำเข้าข้อมูล</li>
    <li>ที่ปรึกษาผู้เชี่ยวชาญ ผู้ให้คำปรึกษา และ/หรือ ผู้ตรวจสอบบัญชีภายนอก</li>
    <li>ผู้ให้บริการซึ่งเป็นบุคคลที่สาม เช่น ผู้ให้บริการด้านการบริหาร หรือด้านการดำเนินการที่เกี่ยวข้องกับธุรกิจของบริษัท เช่น การสื่อสารโทรคมนาคม เทคโนโลยีสารสนเทศ โลจิสติกส์ การส่งสินค้า การประกอบ การติดตั้ง การพิมพ์ การบริการทางไปรษณีย์ หรือบริการด้านการตลาด
        และกิจกรรมส่งเสริมการขาย</li>
    <li>เจ้าหน้าที่หรือหน่วยงานของรัฐที่เกี่ยวข้อง</li>
    <li>บุคคลที่สามที่บริษัทดำเนินธุรกิจด้วยมีสิทธิในการใช้ข้อมูลส่วนบุคคลของท่านเพื่อดำเนินการตามที่กำหนดไว้ในสัญญาที่ทำกับบริษัทเท่านั้น โดยข้อกำหนดส่วนหนึ่งของสัญญาที่บริษัททำกับบุคคลที่สามเหล่านี้กำหนดให้บุคคลดังกล่าวต้องปฏิบัติตามกฎหมายและนโยบายใด ๆ
        ตามที่บริษัทกำหนด และบุคคลเหล่านี้จะต้องใช้มาตรการที่เหมาะสมเพื่อทำให้เกิดความมั่นใจว่าข้อมูลส่วนบุคคลของท่านจะได้รับการคุ้มครองและปลอดภัย</li>
</ol>
<p>	บริษัทฯ จะจัดเก็บข้อมูลส่วนบุคคลเป็นระยะเวลาตามความเหมาะสมต่อการใช้งาน ซึ่งได้มีการกำหนดระยะเวลา (Retention Period) ไว้อย่างชัดเจนตามชนิดของข้อมูลต่าง ๆ ซึ่งเมื่อสิ้นสุดระยะเวลาดังกล่าว บริษัทฯ จะทำการลบข้อมูลส่วนบุคคลดังกล่าวทันที</p>
<h4>การใช้หรือการเปิดเผยข้อมูลส่วนบุคคล</h4>
<ol>
    <li>บริษัทฯ รับรองว่าจะไม่นำข้อมูลส่วนบุคคลของลูกค้าหรือผู้ใช้บริการหรือผู้มีส่วนได้เสียที่มีการเก็บรวบรวมไว้ ไปใช้งานเพื่อวัตถุประสงค์อื่นนอกจากตามวัตถุประสงค์การใช้อันชอบด้วยกฎหมายหรือเพื่อการดำเนินธุรกิจของบริษัทฯ</li>
    <li>บริษัทฯ จะไม่นำข้อมูลดังกล่าวไปจำหน่าย ถ่ายโอน หรือเผยแพร่ให้กับบุคคลภายนอก เว้นแต่จะได้รับความยินยอมจากเจ้าของข้อมูลเท่านั้น</li>
    <li>บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคล หากเป็นข้อมูลที่เปิดเผยต่อสาธารณะโดยชอบด้วยกฎหมาย หรือปฏิบัติตามคำสั่งศาลหรือตามคำสั่งขอหน่วยงานของรัฐ เพื่อประโยชน์แก่การสืบสวน สอบสวนของเจ้าหน้าที่ตามกฎหมาย หรือการพิจารณาคดีของศาล</li>
</ol>
<h4>สิทธิในการควบคุมข้อมูลส่วนบุคคล</h4>
<p>	เจ้าของข้อมูลส่วนบุคคลมีสิทธิ์ต่างๆ ดังนี้</p>
<ol>
    <li>ขอทราบข้อมูล ลบ แก้ไข หรือขอตรวจสอบข้อมูลส่วนบุคคลของตน</li>
    <li>ขอไม่รับข้อมูลการสื่อสารทางการตลาดใด ๆ จากบริษัทฯ</li>
    <li>ขอให้บริษัทฯ ส่งหรือโอนข้อมูลส่วนบุคคลของตนไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น เมื่อสามารถ ทำได้ด้วยวิธีการอัตโนมัติโดยเจ้าของข้อมูลส่วนบุคคลสามารถแจ้งความประสงค์ และระบุตัวตนที่แท้จริงโดยเป็นลายลักษณ์อักษรส่งมายังบริษัทฯ เมื่อบริษัทฯ ได้รับคำร้องดังกล่าว
        บริษัทฯ จะแจ้งถึงความมีอยู่หรือรายละเอียดของข้อมูลให้ทราบภายในระยะเวลาที่อันสมควร</li>
</ol>
<p>	คำร้องขอตามสิทธิ์ของเจ้าของข้อมูลส่วนบุคคลจะได้รับการพิจารณา และจดบันทึก ทั้งนี้บริษัทฯ จะพิจารณาดำเนินการตามคำร้องขอของเจ้าของข้อมูลส่วนบุคคลได้ก็ต่อเมื่อได้ตรวจสอบแล้วว่าไม่ขัดต่อข้อกำหนดใด ๆ ของกฎหมายซึ่งบริษัทฯ พึงต้องปฏิบัติตาม</p>
<h4>ความมั่นคงปลอดภัยในการเก็บรักษาข้อมูลส่วนบุคคล</h4>
<p>	เพื่อให้การจัดการข้อมูลส่วนบุคคลเป็นไปอย่างปลอดภัยและเหมาะสม บริษัทฯ ได้มีมาตรการรักษาความมั่นคงปลอดภัยด้านต่าง ๆ เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคล โดยปราศจากการไม่ได้รับอนุญาตหรือโดยมิชอบ หากมีการละเมิดข้อมูลส่วนบุคคลซึ่งก่อให้เกิดผลความเสียหายต่อเจ้าของข้อมูลส่วนบุคคลโดยตรง
    บริษัทฯ จะทำการแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบถึงเหตุการณ์ละเมิดข้อมูลส่วนบุคคลที่เกิดขึ้นโดยเร็วที่สุดเพื่อระงับความเสียหาย และจะดำเนินการตรวจสอบสาเหตุพร้อมทั้งกำหนดมาตรการเยียวยาอย่างเหมาะสมให้แก่ผู้เสียหาย</p>
<h4>การติดต่อ</h4>
<p>	บริษัทฯ ได้มีการจัดตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล ซึ่งมีหน้าที่ในการดูแลให้มีการปฏิบัติตามนโยบายฉบับนี้ หากเจ้าของข้อมูลส่วนบุคคลหรือผู้มีส่วนได้เสียมีข้อสงสัยเกี่ยวกับนโยบายฉบับนี้หรือต้องการส่งคำร้องตามสิทธิใด ๆ สามารถติดต่อได้ที่ Customer
    Contact Center 1379 หรือเว็ปไซต์ของบริษัทฯ&nbsp;
    <a href="https://www.indexlivingmall.com/www.indexlivingmall.com" target="_blank" style="color: rgb(0, 123, 255); background-color: transparent;">www.indexlivingmall.com</a>
</p>
<h4>การทบทวนนโยบายการคุ้มครองข้อมูลส่วนบุคคล</h4>
<p>	บริษัทฯ อาจปรับปรุงนโยบายฉบับนี้ให้สอดคล้องกับการเปลี่ยนแปลงการดำเนินงานของบริษัทฯ เพื่อตอบสนองต่อข้อเสนอแนะ ความคิดเห็นจากเจ้าของข้อมูลส่วนบุคคล หรือเพื่อปรับปรุงให้เป็นไปตามข้อกำหนดกฎหมาย หากมีการแก้ไขเปลี่ยนแปลงนโยบายฉบับนี้ บริษัทฯ จะประกาศแจ้งการเปลี่ยนแปลงให้เจ้าของข้อมูลส่วนบุคคลที่เกี่ยวข้องทราบอย่างชัดเจนก่อนจะเริ่มดำเนินการเปลี่ยนแปลงทาง&nbsp;
    <a
    href="https://www.indexlivingmall.com/privacy-policy" target="_blank" style="color: rgb(0, 123, 255); background-color: transparent;">www.indexlivingmall.com/privacy-policy</a>&nbsp;หรืออาจส่งประกาศแจ้งเตือนให้เจ้าของข้อมูลส่วนบุคคลทราบโดยตรง</p>
<h4>การใช้บังคับนโยบายคุ้มครองข้อมูลส่วนบุคคล</h4>
<ul>
    <li>	เจ้าของข้อมูลส่วนบุคคลตกลงและรับทราบว่า นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้มีผลใช้บังคับกับข้อมูลส่วนบุคคลทั้งหมดที่บริษัทฯ เป็นผู้เก็บรวบรวม และตกลงให้บริษัทฯ มีสิทธิในการเก็บ รักษา และนำข้อมูลส่วนบุคคลที่บริษัทฯ ได้รวบรวมไว้แล้ว (หากมี) ตลอดจนข้อมูลส่วนบุคคลที่บริษัทฯ
        จัดเก็บในปัจจุบัน และที่จะได้จัดเก็บในอนาคต ไปใช้ หรือเปิดเผยแก่บุคคลอื่น ภายในขอบเขตตามที่ระบุไว้ในนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้</li>
</ul>
`

const ConsentModal = ({ isShowing, toggle }) => {

  return (
    <ModalStyled isOpen={isShowing} toggle={toggle}>
      <ModalBody>
        {/* <ConsentTitle>เงื่อนไขและข้อตกลง</ConsentTitle> */}
        <TextWrapper>
          {
            parse(htmlSnippet)
          }
        </TextWrapper>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
          <PillButton
            block
            color="light"
            onClick={toggle}
            >
              ปิด
              </PillButton>
        </ModalFooter>
      </ModalStyled>
  )
}

export default ConsentModal

const ModalStyled = styled(Modal)`
.modal-dialog {
  max-height: 400px;
}
`

const PillButton = styled(Button)`
  border-radius: 50px;
`

// const ConsentTitle = styled.div`
// font-weight: bold;
// font-size: 18px;
// line-height: 27px;
// margin-bottom: 1.5rem;
// `

const TextWrapper = styled.div`
  max-height: calc(100vh - 180px);
  overflow-y: scroll;
  margin: -1rem;
  padding: 1rem;
`

// const Title = styled.p`
// font-size: 20px;
// font-weight: 600;
// margin-bottom: 0.5rem;
// `
// const SubTitile = styled.p`
// font-size: 18px;
// font-weight: 500;
// margin-bottom: 0.3rem;
// `
// const ContentText = styled.p`
// font-size: 14px;
// margin-bottom: 0.5rem;
// ` 