import React from 'react'
import styled from 'styled-components'
import Barcode from 'react-barcode'
import Skeleton from 'react-loading-skeleton'


const BarcodeEl = (props) => {
  const { dataLoaded, imageLoaded, code } = props

  return (
    <BarcodeWrapper className="barcode-wrapper">
      {
        dataLoaded && imageLoaded ? <Barcode
      value={code}
      format="CODE39"
      fontSize={20}
      font="Kanit"
      /> : <Skeleton height={86} width={250} />
      }
      
    </BarcodeWrapper>
  )
}

export default BarcodeEl

const BarcodeWrapper = styled.div`
  width: 100%;
  text-align: center;

  svg {
    height: 100%;
    width: 70% !important;
  }
`