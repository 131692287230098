import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import styled from 'styled-components'
import parse from 'html-react-parser'

const TermModal = ({ isShowing, toggle, data, lang }) => {

  return (
  <ModalStyled isOpen={isShowing} toggle={toggle}>
      <ModalBody>
        <TextWrapper>
          {parse(data)}
        </TextWrapper>
        </ModalBody>
        <ModalFooter style={{ borderTop: 0 }}>
          <PillButton
            block
            color="light"
            onClick={toggle}
            >
              { lang === 'EN' ? 'Close' : 'ปิด'}
              </PillButton>
        </ModalFooter>
        </ModalStyled>
  )
}

export default TermModal

const ModalStyled = styled(Modal)`
  .modal-dialog {
    max-height: 400px;
  }
`

const PillButton = styled(Button)`
  border-radius: 50px;
`

const TextWrapper = styled.div`
  overflow-y: scroll;
  margin: -1rem;
  padding: 1rem;
  max-height: calc(100vh - 150px);


  h1 {
  font-size: 1.5rem;
  }
  h2 {
  font-size: 1.25rem;
  }
    h3 {
  font-size: 1rem;
  }
  p { 
    font-size: 14px;
  }
`