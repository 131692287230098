import React from 'react'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
// import Skeleton from 'react-loading-skeleton'

// import IndexLogo from '../assets/images/Index-Logo.png'

const CampaignCard = (props) => {
  const { data, dataLoaded, imageLoaded, setImageLoaded, children } = props

  if(!data) return ''

  const onLoad = () => {
    setImageLoaded(true)
  }
  
  return (
    <CardStyled>
      <Wrapper>
      <img className="card-img-top" alt="" src={data.coverImage} onLoad={onLoad} style={{ display: `${dataLoaded && imageLoaded ? 'block': 'none'}`}} />
      {/* <LogoWrapper>
          <img alt="" src={IndexLogo} width="80" style={{ display: `${dataLoaded && imageLoaded ? 'flex': 'none'}`}} />
          <Skeleton width={80} style={{ display: `${dataLoaded && imageLoaded ? 'none': 'flex'}`}} />
      </LogoWrapper> */}
      {/* <TextWrapper>
        <CampaignTitle>{dataLoaded && imageLoaded ? `${data.name}` : <Skeleton width={200}/>}</CampaignTitle>
        <CampaignDescription>{dataLoaded && imageLoaded ? `${data.description}` : <Skeleton width={300}/>}</CampaignDescription>
      </TextWrapper> */}
    </Wrapper>
      <CardBody>
        {children}
      </CardBody>
    </CardStyled>
  )
}

export default CampaignCard

const CardStyled = styled(Card)`
-webkit-box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.25);
box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.25);
`

const Wrapper = styled.div`
  background-color: #cccccc;
  position: relative;
  padding-bottom: 56.25%;

  .card-img-top {
    position: absolute;
    height: 100%;
  }
`

// const LogoWrapper = styled.div`
//   position: absolute;
//   padding-top: 5px;
//   top: 5px;
//     right: 10px;
//   z-index: 1;
// `

// const TextWrapper = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   min-height: 60px;
//   background: rgb(255,255,255, 0.4);
//   padding: 0.25rem 1rem;
// `
// const CampaignTitle = styled.div`
// font-style: normal;
// font-weight: bold;
// font-size: 20px;
// line-height: 30px;
// `

// const CampaignDescription = styled.div`
// font-style: normal;
// font-weight: normal;
// font-size: 14px;
// line-height: 21px;
// `